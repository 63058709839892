import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from 'config'
import { findSubscriptions } from 'api/subscriptions'
import Button from 'components/buttons/Button'
import { H2, Paragraph } from 'components/typography'
import SubscriptionCard from 'components/cards/subscriptions/SubscriptionCard'
import RingsSpinner from 'components/spinners/RingsSpinner'
import styles from './Subscriptions.module.css'

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState({})
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    setIsFetching(true)
    findSubscriptions()
      .then(setSubscriptions)
      .catch(_ => null)
      .finally(() => setIsFetching(false))
  }, [])

  if (isFetching) return <RingsSpinner />
  return (
    <section className={styles.section}>
      <div className={styles.toolbar}>
        <H2>{subscriptions.length > 1 ? 'Tus planes' : 'Tu plan'}</H2>
        {!config.hide && (
          <Link to='create'>
            <Button size='small' label='Añadir' />
          </Link>
        )}
      </div>
      <div className={styles.container}>
        {!subscriptions.length && (
          <Paragraph>Actualmente no tienes ningún plan contratado.</Paragraph>
        )}
        {subscriptions
          .sort((a, b) => b.id.localeCompare(a.id))
          .map(subscription => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              onClick={() => handleButtonClick(subscription.id)}
            />
          ))}
      </div>
    </section>
  )
}

export default Subscriptions
